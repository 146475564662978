 <template>
    <div>
      <header class="sidebarClone w-100">
        <div class="haeder-left w-100">
          <div class="header-logo w-100 p-0 border-0">
            <img src="/img/logo.e38ad230.png" />
          </div>
        </div>
      </header>
      <main class="sidebarClone w-100">
        <section class="inner-page-outer thankyoupage">
            <span class="sr-only">Please Wait...</span>
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
            </div>
          </div>
        </section>
      </main>
    </div>
  </template>
  <script>
  import callService from "@/services/Service";
  const { getData, getByID, postData } = callService();
  import axios from "axios";
  export default {
    name: "SwitchToUser",
    data: () => {
      return {
        validToken: null,
      };
    },
    async mounted() {
      this.validToken = this.$route.params.token;
      if (this.validToken != '') {
        try {
          const response = await getByID("api/getUserLoginDetails/",this.validToken);
          if (response.status === 200 && response.data.statuscode === 1) {
            const result = response.data.result;
            localStorage.setItem("LogIn", JSON.stringify(result));
            if (localStorage.getItem("LogIn")) {
              if (result.extension !== "") {
                localStorage.setItem("ServerPath", "/ws");
                localStorage.setItem("EchoCancellation", "1");
                localStorage.setItem("VoiceMailSubscribe", "1");
                localStorage.setItem("SipDomain", "dailer.caretalkhosting.com");
                localStorage.setItem("profileName", result.extension);
                localStorage.setItem("SipUsername", result.extension);
                localStorage.setItem("AspectRatio", "1.33");
                localStorage.setItem("VideoSrcId", "default");
                localStorage.setItem("SipPassword", "SIP123ext");
                localStorage.setItem("loglevel:webpack-dev-server", "WARN");
                localStorage.setItem("AutoGainControl", "1");
                localStorage.setItem("wssServer", "dailer.caretalkhosting.com");
                localStorage.setItem(
                  "RingOutputId",
                  "724c6dad96e08e09da3ed3fb21ed79a3966a0e916eb4a267d108a02ae19ea4ee"
                );
                localStorage.setItem("WebSocketPort", "8089");
                localStorage.setItem("NoiseSuppression", "1");
                localStorage.setItem("language", "en");
                localStorage.setItem("WelcomeScreenAccept", "yes");
                localStorage.setItem("InstanceId", String(Date.now()));
                localStorage.setItem(
                  "16750799442502185-Buddies",
                  '{"TotalRows":0,"DataCollection":[]}'
                );
                localStorage.setItem("profileUserID", "167508055739511B1");
                localStorage.setItem("ChatEngine", "SIMPLE");
                localStorage.setItem("AudioOutputId", "default");
                localStorage.setItem("AudioSrcId", "default");
                localStorage.setItem("VideoOrientation", "rotateY(180deg)");
                localStorage.setItem(
                  "profileVcard",
                  '{"TitleDesc":"","Mobile":"","Email":"","Number1":"","Number2":""}'
                );
                localStorage.setItem("Notifications", "0");
                localStorage.setItem(
                  "167508055739511B1-Buddies",
                  '{"TotalRows":0,"DataCollection":[]}'
                );
              } else {
                setTimeout(function () {
                  ShowContacts();
                }, 3000);
              }
              const type_uid = JSON.parse(localStorage.getItem("LogIn")).type_uid;
              const redirction =
                type_uid === "ipg4bi4vg5"
                  ? "SuperUserDashboard"
                  : type_uid === "ipg4bi4vg6"
                  ? "EnrollmentAdminProfile"
                  : type_uid === "ipg4bi4vg7"
                  ? "ClinicalAdminProfile"
                  : type_uid === "ipg4bi4vg8"
                  ? "GeneralAdminProfile"
                  : type_uid === "ipg4bi4v10"
                  ? "agentprofile"
                  : type_uid === "ipg4bi4vg9"
                  ? "EnrollmentManagerProfile"
                  : type_uid === "ipg4bi4v11"
                  ? "PhysicianAdminProfile"
                  : type_uid === "ipg4bi4v12"
                  ? "PhysicianProfile"
                  : type_uid === "Enrollment"
                  ? "managerProfile"
                  : "login";
              this.$router.push({ name: redirction });
            }
          } else {
            const redirction =   "login";
            this.$router.push({ name: redirction });
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }else {
             const redirction =   "login";
             this.$router.push({ name: redirction });
          }
    },
  };
  </script>
  